.link {
  line-height: 0;
}

.action {
  margin: var(--spacing-00) var(--spacing-20);
  padding: var(--spacing-30);
  border-radius: 100%;
  cursor: pointer;
}
.action.compact {
  padding: var(--spacing-20);
}
.action.negativeVerticalMargin {
  margin-top: calc(-1 * var(--spacing-30));
  margin-bottom: calc(-1 * var(--spacing-30));
}
.action.shiftLeft:first-child {
  margin-left: calc(-1 * var(--spacing-30));
}
.action.shiftRight:last-child {
  margin-right: calc(-1 * var(--spacing-30));
}
.action:hover {
  background-color: var(--color-highlight);
}

.action.selected {
  background-color: var(--color-highlight-dark);
}
.action.selected:hover {
}

.action.disabled {
  color: var(--color-dull);
  cursor: default;
  pointer-events: none;
}
.action.disabled:hover {
  background-color: transparent;
}

.svg {
  color: inherit;
}
.svg.primary {
  color: var(--color-primary);
}

.disabled {
  color: var(--color-dull);
}