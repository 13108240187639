.elevation1Dp {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.elevation2Dp {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.elevation3Dp {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.elevation4Dp {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
}
.elevation5Dp {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}