html {
  height: 100%;
  background-color: var(--color-grey-010);
  /* background-color: var(--color-primary-extra-light); */
  /*background: var(--color-body-overflow, #192733);*/
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  height: unset !important;
  min-width: 320px;
  min-height: 100%;
  background: white;
  margin: 0;
  overflow-y: auto;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--color-background-light);
  
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: unset;
}

:focus {
  outline: none;
}

:global(.mainContainer) {
  display: flex;
  flex-direction: row;
  padding-top: 64px;
  margin-top: -64px;
  min-height: 100vh;
}

:global(.sideNav) {
  flex-grow: 0;
  flex-shrink: 0;
}

:global(#app) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}

:global(#auth) {
  display: flex;
  flex-direction: column;
  height: 100%;
}

:global(.bodyHeaderContainer) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  background-color: var(--color-grey-010);
}

:global(.header) {
  position: relative;
  width: 100%;
}

:global(.bodyContainer) {
  /*min-height: 100vh;*/
  /* margin-top: -64px;
  padding-top: 64px; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;

}

:global(.body) {
  display: flex;
  flex-direction: column;
  padding: 16px;
  flex-grow: 1;
}