/*https://gist.github.com/devinrhode2/2573411*/

.scrollbar {
  scrollbar-base-color: rgb(255, 255, 255); /*IE*/
  scrollbar-face-color: rgb(255, 255, 255);
  scrollbar-track-color: rgb(239, 240, 241);
  scrollbar-arrow-color: rgb(255, 255, 255);
}
.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.scrollbar::-webkit-scrollbar:hover {
  background-color: rgba(255, 255, 255, 0.9);
}
.scrollbar::-webkit-scrollbar-track {
  -webkit-border-radius: 100px;
}
.scrollbar::-webkit-scrollbar-track:hover {
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 100px;
}
.scrollbar::-webkit-scrollbar-thumb:active,
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

.scrollX {
  composes: scrollbar;
  overflow-x: auto;
  /*overflow-x: overlay;*/
}
.scrollY {
  composes: scrollbar;
  overflow-y: auto;
  /*overflow-y: overlay;*/
}

.scroll {
  composes: scrollX;
  composes: scrollY;
}

.scrollableContainer {
  position: relative;
  flex-grow: 1;
  align-self: stretch;
}
.scrollableContainer > * {
  position: absolute;
}

.scrollableContainerX {
  composes: scrollableContainer;
  composes: scrollX;
}
.scrollableContainerX > * {
  min-height: 100%;
}
.scrollableContainerY {
  composes: scrollableContainer;
  composes: scrollY;
}
.scrollableContainerY > * {
  min-width: 100%;
}