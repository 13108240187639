.container {
  position: fixed;
  padding: var(--spacing-30) var(--spacing-50);
  bottom: 0px;
  z-index: 42;
}

.snackbar {
  padding: var(--spacing-30) var(--spacing-50);
  border-radius: 0px;
  background-color: var(--color-mute);
  color: var(--color-white);
}

.actionContainer {
  margin-left: var(--spacing-30);
  flex-shrink: 0;
  justify-content: flex-end;
}
