.alignLeft {
  text-align: left;
  align-self: flex-start;
}
.alignRight {
  text-align: right;
  align-self: flex-end;
}
.alignCenter {
  text-align: center;
  align-self: center;
}

.alignStretch {
  align-self: stretch;
}