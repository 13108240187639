:root {
  --spacing-00: 0px;
  --spacing-10: 2px;
  --spacing-20: 4px;
  --spacing-30: 8px;
  --spacing-40: 12px;
  --spacing-50: 16px;
  --spacing-60: 20px;
  --spacing-70: 24px;
  --spacing-80: 32px;
  --spacing-90: 48px;
  --spacing-100: 64px;
}
