:root {
  --color-primary: #0fb5ba;
  --color-primary-light: #1cd4d4;
  --color-primary-extra-light: #e1fcf8;
  /* --color-complementary: #0552b5; */
  --color-secondary-dark: #002159;
  --color-secondary: #0552b5;
  --color-secondary-light: #0967d2;
  --color-secondary-extra-light: #E6F6FF;
  --color-background-dark: #3e4c59;
  --color-background-light: #f5f7fa;
  --color-body-overflow: #192733;
  --color-white: var(--color-grey-010);
  --color-black: #1f2933;
  --color-mute: #616e7c;
  --color-dull: #cbd2d9;
  --color-red: var(--color-red-060);
  --color-border: #e4e7eb;
  --color-highlight: #e4e7eb;
  --color-highlight-dark: #cbd2d9;
  --color-placeholder-default: #757575;
  --color-pending:#D97706;
  --color-grey-10: #f5f7fa;
  --color-grey-20: #e4e7eb;
  --color-grey-30: #cbd2d9;
  --color-grey-40: #616e7c;
 

  --color-link: #0079D0;
  --color-gray: #eee;
  --color-dark-gray: #bbb;
  --color-green: var(--color-green-060); 
  --color-badge-red: var(--color-red-060); 
  --color-success: var(--color-green-060);
  --color-success-background: var(--color-green-010);
  --color-warning: var(--color-yellow-060);
  --color-warning-background: var(--color-yellow-010);
  --color-error: var(--color-red-060);
  --color-error-background: var(--color-red-010);
}
