.width5 {
  max-width: 5%;
  min-width: 5%;
}

.width10 {
  max-width: 10%;
  min-width: 10%;
}

.width15 {
  max-width: 15%;
  min-width: 15%;
}

.width20 {
  max-width: 20%;
  min-width: 20%;
}

.width25 {
  max-width: 25%;
  min-width: 25%;
}

.width30 {
  max-width: 30%;
  min-width: 30%;
}

.width35 {
  max-width: 35%;
  min-width: 35%;
}

.width40 {
  max-width: 40%;
  min-width: 40%;
}

.width45 {
  max-width: 45%;
  min-width: 45%;
}

.width50 {
  max-width: 50%;
  min-width: 50%;
}

.width55 {
  max-width: 55%;
  min-width: 55%;
}

.width60 {
  max-width: 60%;
  min-width: 60%;
}

.width65 {
  max-width: 65%;
  min-width: 65%;
}

.width70 {
  max-width: 70%;
  min-width: 70%;
}

.width75 {
  max-width: 75%;
  min-width: 75%;
}

.width80 {
  max-width: 80%;
  min-width: 80%;
}

.width85 {
  max-width: 85%;
  min-width: 85%;
}

.width90 {
  max-width: 90%;
  min-width: 90%;
}

.width95 {
  max-width: 95%;
  min-width: 95%;
}

.width100 {
  max-width: 100%;
  min-width: 100%;
}

.layout-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
.layout-row-reverse {
  display: flex;
  flex-direction: row-reverse;
  box-sizing: border-box;
}
.layout-column {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.layout-column-reverse {
  display: flex;
  flex-direction: column-reverse;
  box-sizing: border-box;
}

.layout-justify-start {
  justify-content: start;
}
.layout-justify-flex-start {
  justify-content: flex-start;
};
.layout-justify-center {
  justify-content: center;
}
.layout-justify-end {
  justify-content: end;
}
.layout-justify-flex-end {
  justify-content: flex-end;
}
.layout-justify-space-around {
  justify-content: space-around;
}
.layout-justify-space-between {
  justify-content: space-between;
}
.layout-justify-space-evenly {
  justify-content: space-evenly;
}

.layout-align-start {
  align-items: start;
}
.layout-align-flex-start {
  align-items: flex-start;
}
.layout-align-center {
  align-items: center;
}
.layout-align-end {
  align-items: end;
}
.layout-align-flex-end {
  align-items: flex-end;
}
.layout-align-stretch {
  align-items: stretch;
}

/*.flex-order--8 {
  order: -8;
}
.flex-order--7 {
  order: -7;
}
.flex-order--6 {
  order: -6;
}
.flex-order--5 {
  order: -5;
}
.flex-order--4 {
  order: -4;
}
.flex-order--3 {
  order: -3;
}
.flex-order--2 {
  order: -2;
}
.flex-order--1 {
  order: -1;
}*/
.flex-order-0 {
  order: 0;
}
.flex-order-1 {
  order: 1;
}
.flex-order-2 {
  order: 2;
}
.flex-order-3 {
  order: 3;
}
.flex-order-4 {
  order: 4;
}
.flex-order-5 {
  order: 5;
}
.flex-order-6 {
  order: 6;
}
.flex-order-7 {
  order: 7;
}
.flex-order-8 {
  order: 8;
}
